import Button from 'src/shared/ui/button/button/button';
import HamsterErrorIconSvg from 'src/shared/assets/images/hamster-error.svg';
import HamsterErrorHoverIconSvg from 'src/shared/assets/images/hamster-error-hover.svg';

import { useEffect, useState } from 'react';
import styles from './disconnected-ws-modal-content.module.scss';

const DisconnectedWsModalContent = () => {
  const [switchHamster, setSwitchHamster] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setSwitchHamster(true);
      const timeout = setTimeout(() => {
        setSwitchHamster(false);
      }, 500);
      return () => clearTimeout(timeout);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.content}>
      {!switchHamster ? <img src={HamsterErrorIconSvg} alt="hamster" /> : <img src={HamsterErrorHoverIconSvg} alt="hamster" />}
      <h3>Соединение <br /> было прервано</h3>
      <p>Пожалуйста, обновите страницу, <br /> чтобы восстановить соединение</p>
      <Button onClick={() => window.location.reload()} maxWidth="200px" background="green">Обновить страницу</Button>
    </div>
  );
};

export default DisconnectedWsModalContent;
