import className from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Rebates } from 'src/app/store/slices/user/types';
import { TradingType } from 'src/shared/types/global-types';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import Hint from 'src/shared/ui/help/hint';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import { REBATE_EXCHANGES_INFO } from './constants';
import BinanceHintContent from './components/binance-hint-content';
import BybitHintContent from './components/bybit-hint-content';
import styles from './rebate-widget.module.scss';

interface IRebateWidgetProps {
  exchange: Rebates
}

const RebateWidget = ({ exchange }: IRebateWidgetProps) => {
  const { getExchangeImageById, getExchangeNameById } = useExchange();

  const exchangeName = getExchangeNameById(exchange.exchange_id);
  const [selectedType, setSelectedType] = useState<TradingType>('FUTURES');

  const balanceVisible = useSelector(selectHiddenBalance);

  const hintLabel = REBATE_EXCHANGES_INFO.find((exchange) => exchange.exchangeName === exchangeName)?.hintLabel;
  const tradingTypes = REBATE_EXCHANGES_INFO.find((exchange) => exchange.exchangeName === exchangeName)?.tradingType;

  const getRebatePercentByType = (type: string): string => exchange.rebate_percent_by_type.find((item) => item.trading_type === type)?.rebate_percent || '0';

  const renderHint = () => {
    if (exchangeName === 'Binance') {
      return (
        <BinanceHintContent rebatePercent={getRebatePercentByType(selectedType)} />
      );
    }

    if (exchangeName === 'Bybit') {
      const rebate = {
        maker: exchange.rebate_percent_by_type.find((item) => item.trading_type === selectedType)?.maker_commission || '0',
        taker: exchange.rebate_percent_by_type.find((item) => item.trading_type === selectedType)?.taker_commission || '0',
      };

      return (
        <BybitHintContent
          rebatePercent={getRebatePercentByType(selectedType)}
          maker={rebate.maker}
          taker={rebate.taker}
          typeLabel={selectedType}
        />
      );
    }

    if (exchangeName === 'Bingx') {
      const rebate = {
        maker: exchange.rebate_percent_by_type.find((item) => item.trading_type === selectedType)?.maker_commission || '0',
        taker: exchange.rebate_percent_by_type.find((item) => item.trading_type === selectedType)?.taker_commission || '0',
      };

      return (
        <BybitHintContent
          rebatePercent={getRebatePercentByType(selectedType)}
          maker={rebate.maker}
          taker={rebate.taker}
          typeLabel={selectedType}
        />
      );
    }

    return (<div>Нет данных</div>);
  };

  const renderRebatePercentLabel = (): string => {
    if (exchangeName === 'Bybit') {
      return `Скидка +${getRebatePercentByType(selectedType)}%`;
    }

    return `${getRebatePercentByType(selectedType)}%`;
  };

  return (
    <div className={className(
      styles.container,
      { [styles.binance]: exchangeName === 'Binance' },
      { [styles.bybit]: exchangeName === 'Bybit' },
      { [styles.bingX]: exchangeName === 'Bingx' },
    )}
    >
      <nav className={styles.navigation}>
        <ul>
          {
            tradingTypes?.map((typeTrading) => (
              <li key={typeTrading.type}>
                <button
                  className={className(styles.navLink, { [styles.active]: selectedType === typeTrading.type })}
                  onClick={() => setSelectedType(typeTrading.type as TradingType)}
                >
                  {typeTrading.name}
                </button>
              </li>
            ))
          }
        </ul>

        <span className={styles.rebatePercent}>{!balanceVisible ? renderRebatePercentLabel() : HIDDEN_BALANCES}</span>
        <div className={styles.info}>
          {hintLabel}
          <Hint
            position="bottom-end"
            hintOpen
            offset={2}
            mawText={250}
            hintText={renderHint()}
            text=""
          />
        </div>
        <img className={styles.exchangeImage} src={getExchangeImageById(exchange.exchange_id)} alt="exchange" />
      </nav>
    </div>
  );
};

export default RebateWidget;
