import className from 'classnames';
import Decimal from 'decimal.js';
import {
  useState,
  useMemo,
  useLayoutEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectBalances } from 'src/app/store/slices/balance/selectors';
import { LoadingOverlay } from '@mantine/core';

import { ReactComponent as CaseIconSvg } from 'src/shared/assets/images/account/case.svg';
import { HIDDEN_BALANCES, USDT_PRECISION } from 'src/shared/constants/constants';

import { EStatus, Nullable } from 'src/shared/types/global-types';
import { toFixedDecimalPrecision, plusValues } from 'src/shared/libs/helpers/helper.lib';

import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import styles from './balance.module.scss';

interface IBalanceProps {
  subAccountId?: Nullable<number>
}

const Balance = ({ subAccountId }: IBalanceProps) => {
  const location = useLocation();
  const { balances, status } = useSelector(selectBalances);
  const { pathname } = useLocation();

  const balanceVisible = useSelector(selectHiddenBalance);

  const [subAccountBalance, setSubAccountBalance] = useState<Nullable<string>>(null);
  const [subAccountPnl, setSubAccountPnl] = useState<Nullable<string>>('');

  const totalBalances = useMemo(() => {
    if (balances) {
      const total = balances.sub_accounts.reduce(
        (acc, curr) => {
          acc.spot = new Decimal(acc.spot).plus(curr.spot).toString();
          acc.futures = new Decimal(acc.futures).plus(curr.futures).toString();
          return acc;
        },
        { spot: '0', futures: '0' },
      );
      const totalSum: string = plusValues(total.spot, total.futures, USDT_PRECISION);
      return totalSum;
    }
    return '0.0';
  }, [balances]);

  useLayoutEffect(() => {
    const subAccountIdParsed = location.pathname.split('/').find((el) => Number(el));

    if (subAccountIdParsed) {
      const findBalance = balances?.sub_accounts.find((balance) => balance.sub_account_id === Number(subAccountIdParsed));
      const findPnl = findBalance?.total_pnl_usdt;

      if (findBalance) {
        const totalSum: string = plusValues(findBalance.spot, findBalance.futures);
        setSubAccountBalance(totalSum);
      }

      if (findPnl) {
        if (findPnl === '-0.0') {
          setSubAccountPnl('0.0');
        } else {
          setSubAccountPnl(findPnl);
        }
      }
    }
  }, [pathname, balances]);

  return (
    <section className={styles.balanceAccounts}>
      <div className={styles.titleWrapper}>
        <div className={styles.case}>
          <CaseIconSvg />
        </div>
        <p className={styles.title}>{subAccountId ? <span>Баланс <br /> счета, USDT</span> : <span>Баланс <br /> всех счетов, USDT</span>}</p>
      </div>

      <div className={styles.sumWrapper}>
        { status === EStatus.loading && (
          <div className={styles.loaderWrapper}>
            <LoadingOverlay
              className="loader"
              visible
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: '#00C37C', type: 'dots' }}
            />
          </div>
        )}
        { status === EStatus.success && (
          <span className={styles.sum}>{!balanceVisible ? `≈ ${toFixedDecimalPrecision(subAccountBalance || totalBalances, USDT_PRECISION)}` : HIDDEN_BALANCES} USDT</span>
        )}
        { status === EStatus.rejected && (
          <span className={styles.error}>Ошибка при получении баланса</span>
        )}
      </div>

      <div className={styles.balanceFooter}>
        <span className={styles.title}>PNL за сегодня: </span>
        <div className={className(
          styles.pnlUsdt,
          { [styles.plus]: subAccountPnl ? Number(subAccountPnl) > 0 : Number(balances?.total_pnl_usdt || 0) > 0 },
          { [styles.minus]: subAccountPnl ? Number(subAccountPnl) < 0 : Number(balances?.total_pnl_usdt || 0) < 0 },
        )}
        >
          <span>{!balanceVisible ? `≈ ${(subAccountPnl || balances?.total_pnl_usdt || '0.0')}` : HIDDEN_BALANCES} USDT</span>
        </div>
      </div>
    </section>
  );
};

export default Balance;
