import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store/store';

export const selectUser = (state: RootState) => state.user;
export const selectKycVerify = (state: RootState) => state.user.user?.is_verified;
export const selectTheme = (state: RootState) => state.user.settings?.theme;
export const selectUserSettings = (state: RootState) => state.user.settings;
export const selectStatusUserSettings = (state: RootState) => state.user.statusUserSettings;
export const selectUserRebatesSettings = (state: RootState) => state.user.rebates.settings;

export const selectTradeCategories = createSelector(
  selectUser,
  (tradeCategories) => tradeCategories.tradeCategories,
);
