import { Nullable, TradingType } from 'src/shared/types/global-types';
import { TRADING_TYPES } from 'src/shared/constants/constants';
import { FilterOption } from '../../model/types';

export const formatDateTime = (isoDateString: Nullable<Date>): { date: string; time: string } => {
  if (!isoDateString) {
    return {
      date: '',
      time: '-',
    };
  }

  const date = new Date(isoDateString);

  // Check if the value is an instance of Date and if it is correct
  if (!(date instanceof Date) || Number.isNaN(date.getTime())) {
    return {
      date: '',
      time: '-',
    };
  }

  // Форматирование даты в формате dd.MM.yy
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);
  const formattedDate = `${day}.${month}.${year}`;

  // Форматирование времени в формате HH:mm:ss
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

export const getDealText = (count: number): string => {
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return `${count} сделок`;
  }
  if (lastDigit === 1) {
    return `${count} сделка`;
  } if (lastDigit >= 2 && lastDigit <= 4) {
    return `${count} сделки`;
  }
  return `${count} сделок`;
};

export const getTradingTypeName = (type: TradingType): string | undefined => {
  const result = TRADING_TYPES.find((item) => item.type === type);
  return result?.name;
};

export const getLabelOfValue = (data: FilterOption[], value: [number | null, number | null]) => {
  const result = data.find((item) => item.value === value);
  return result?.label;
};

export const formatTime = (seconds: number): string => {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const parts = [];

  if (days > 0) {
    parts.push(`${days}д`);
  }
  if (hours > 0) {
    parts.push(`${hours}ч`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}м`);
  }
  if (secs > 0 || parts.length === 0) {
    // Добавляем секунды, если они есть или если нет других частей
    parts.push(`${secs}с`);
  }

  return parts.join(', ');
};

export const onlyIntegers = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === '.' || event.key === ',') {
    event.preventDefault();
  }
};

export const geTickerParamstFromLocalStorage = () => {
  try {
    const storedValue = localStorage.getItem('tickerParams');
    if (storedValue) {
      return JSON.parse(storedValue);
    }
  } catch (error) {
    console.error(`Failed to get tickerParams from localStorage: ${error}`);
  }

  return null;
};
