import { Link } from 'react-router-dom';
import { LEGAL_INFORMATION, USER_AGREEMENT } from 'src/shared/constants/app-links';
import styles from './body-footer.module.scss';

const BodyFooter = () => (
  <div className={styles.bodyFooter}>
    <nav>
      <ul>
        <li><Link to={USER_AGREEMENT} target="_blank">Правила пользования Продуктами и Услугами Компании</Link></li>
        <li><Link to={LEGAL_INFORMATION} target="_blank">Правовая информация</Link></li>
      </ul>
    </nav>

    <p className={styles.registration}>
      LOTSOFT INC. | 155755565
    </p>
  </div>
);

export default BodyFooter;
