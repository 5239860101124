import { Exchange } from 'src/app/store/slices/exchanges/types';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { ColumnOrderItem } from 'src/pages/trading/model/types';

export const bonusHistoryColumn: ColumnOrderItem[] = [
  { key: 'created_at', label: 'Дата и время' },
  { key: 'exchange', label: 'Биржа' },
  { key: 'sub_account_id', label: 'Счёт' },
  { key: 'quantity', label: 'Сумма' },
  { key: 'commission', label: 'Комиссия' },
  { key: 'asset', label: 'Актив' },
];

export const bonusHistoryColumns = {
  created_at: {
    show: true,
    name: 'Дата и время',
  },
  exchange: {
    show: true,
    name: 'Биржа',
  },
  sub_account_id: {
    show: true,
    name: 'Счёт',
  },
  quantity: {
    show: true,
    name: 'Сумма',
  },
  commission: {
    show: true,
    name: 'Комиссия',
  },
  asset: {
    show: true,
    name: 'Актив',
  },
};

export const allSubAccounts: SubAccountType = {
  id: 1014599373722360,
  email: '',
  exchange_username: '',
  exchange_id: 0,
  is_favorite: false,
  user_name: 'Все',
  is_active_exchange: true,
};

export const allExchanges: Exchange = {
  id: 1812591373722361,
  created_at: '',
  image: '',
  updated_at: '',
  name: 'Все',
  is_active: true,
};
