import {
  CreatePositionCategory, PositionsSummary, TradesSummary, TradesSummaryUpdate,
} from 'src/pages/diary/pages/trade-list/model/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EStatus, Nullable } from 'src/shared/types/global-types';

import { saveToLocalStorage } from 'src/shared/libs/helpers/helper.lib';
import {
  fetchCreatePositionCategory, fetchFigures, fetchPositionsSummaryTrades, fetchPositionSummaryPublicCreate, fetchPositionSummaryPublicVisible, fetchTradeList,
} from './thunk';
import {
  FetchTickerParams,
  ITradeListSlice, TradeListFilterKey, TradeListFilterValue,
} from './types';
import { geTickerParamstFromLocalStorage } from '../libs/helpers';

const initialState: ITradeListSlice = {
  tradeList: null,
  selectedPositions: [],
  figures: [],
  tradefilters: {
    symbol: null,
    timePeriod: null,
    subAccountId: null,
    exchangeId: null,
    pnlPercent: null,
    duration: null,
    category: null,
    side: null,
    leverage: null,
    profit: null,
    activeQuantityUsdt: null,
    activeQuantity: null,
    tradingType: null,
    page: 1,
  },
  status: EStatus.loading,
  statusCreateSharedLink: EStatus.success,
  statusSharedLinkVisible: EStatus.success,
  tradeListColumns: null,
  positionSummaryTrades: null,
  positionSummaryTradesStatus: EStatus.loading,
  candlesStatus: EStatus.loading,
  pnlValue: '',
  durationValue: '',
  leverageValue: '',
  profitValue: '',
  quantityUsdtValue: '',
  resetFiltersToggle: false,
  fetchTickerParams: geTickerParamstFromLocalStorage(),
};

export const tradeListSlice = createSlice({
  name: 'tradeList',
  initialState,
  reducers: {
    setSelectedPositions: (state, action) => {
      state.selectedPositions = action.payload;
    },
    addPositionSummary: (state, action: PayloadAction<PositionsSummary>) => {
      if (state.tradeList) {
        state.tradeList.items.unshift(action.payload);
      } else {
        state.tradeList = {
          items: [action.payload],
          meta: {
            total_items: 1,
            total_pages: 1,
          },
        };
      }
    },
    updatePositionSummuryDiary: (state, action: PayloadAction<Partial<PositionsSummary>>) => {
      /* Этот код нужен для оптимизации таблицы дневника
      Если обёект positionSummaryTrades есть - это означает, что открыт график, значит таблицу позиций уже не нужно обновлять
      */ if (state.positionSummaryTrades) return;

      if (state.tradeList) {
        const findIndex = state.tradeList.items.findIndex((item) => item.id === action.payload.id);

        if (findIndex !== -1) {
          state.tradeList.items[findIndex] = {
            ...state.tradeList.items[findIndex],
            ...action.payload,
          };
        }
      }
    },
    setTableColumns: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.tradeListColumns = action.payload;
      }

      if (typeof action.payload === 'object' && action.payload !== null) {
        if (!state.tradeListColumns) return;

        state.tradeListColumns = state.tradeListColumns.map((column) => (column.key === action.payload.key
          ? { ...column, visible: action.payload.visible }
          : column));
      } else {
        state.tradeListColumns = action.payload;
      }
    },
    setCandlesStatus: (state, action: PayloadAction<EStatus>) => {
      state.candlesStatus = action.payload;
    },
    setTradeFilter: <T extends TradeListFilterKey>(
      state: ITradeListSlice,
      action: PayloadAction<{ key: T; value: TradeListFilterValue<T> }>,
    ) => {
      state.tradefilters[action.payload.key] = action.payload.value;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.tradefilters.page = action.payload;
    },
    setTickerParams: (state, action: PayloadAction<Nullable<FetchTickerParams>>) => {
      saveToLocalStorage('tickerParams', action.payload);
      state.fetchTickerParams = action.payload;
    },
    removePositionSummaryTrades: (state) => {
      state.positionSummaryTrades = null;
      state.positionSummaryTradesStatus = EStatus.loading;
    },
    resetFilters: (state) => {
      state.tradefilters = {
        symbol: null,
        timePeriod: null,
        subAccountId: null,
        exchangeId: null,
        pnlPercent: null,
        duration: null,
        category: null,
        side: null,
        leverage: null,
        profit: null,
        activeQuantity: null,
        activeQuantityUsdt: null,
        tradingType: null,
        page: 1,
      };
      state.pnlValue = '';
      state.durationValue = '';
      state.leverageValue = '';
      state.profitValue = '';
      state.quantityUsdtValue = '';
      state.resetFiltersToggle = true;
    },
    setPnlValue: (state, action: PayloadAction<string>) => {
      state.pnlValue = action.payload;
    },
    setDurationValue: (state, action: PayloadAction<string>) => {
      state.durationValue = action.payload;
    },
    setLeverageValue: (state, action: PayloadAction<string>) => {
      state.leverageValue = action.payload;
    },
    setProfitValue: (state, action: PayloadAction<string>) => {
      state.profitValue = action.payload;
    },
    setQuantityUsdtValue: (state, action: PayloadAction<string>) => {
      state.quantityUsdtValue = action.payload;
    },
    setResetFilters: (state, action: PayloadAction<boolean>) => {
      state.resetFiltersToggle = action.payload;
    },
    addTradeSummary: (state, action: PayloadAction<TradesSummary>) => {
      if (state.positionSummaryTrades && state.positionSummaryTrades.id === action.payload.position_summary_id) {
        state.positionSummaryTrades.trades_summary.push(action.payload);
      }
    },
    updateTradeSummary: (state, action: PayloadAction<TradesSummaryUpdate>) => {
      if (state.positionSummaryTrades && state.positionSummaryTrades.id === action.payload.position_summary_id) {
        const findIndexTradeSumarry = state.positionSummaryTrades.trades_summary.findIndex((trade) => trade.id === action.payload.id);

        if (findIndexTradeSumarry !== -1) {
          state.positionSummaryTrades.trades_summary[findIndexTradeSumarry] = {
            ...state.positionSummaryTrades.trades_summary[findIndexTradeSumarry],
            ...action.payload,
          };
        }
      }
    },
    updatePositionSummaryTrades: (state, action) => {
      const updatedPositionSummary = action.payload;

      if (updatedPositionSummary.id === state.positionSummaryTrades?.id) {
        state.positionSummaryTrades = {
          ...state.positionSummaryTrades,
          ...updatedPositionSummary,
        };
      }
    },
    clearTradeListSlice: (state) => {
      state.tradeList = null;
      state.tradeListColumns = null;
      state.figures = [];
      state.tradefilters = {
        symbol: null,
        timePeriod: null,
        subAccountId: null,
        exchangeId: null,
        pnlPercent: null,
        duration: null,
        category: null,
        side: null,
        leverage: null,
        profit: null,
        activeQuantity: null,
        activeQuantityUsdt: null,
        tradingType: null,
        page: 1,
      };
      state.resetFiltersToggle = true;
      state.candlesStatus = EStatus.loading;
      state.positionSummaryTrades = null;
      state.pnlValue = '';
      state.durationValue = '';
      state.leverageValue = '';
      state.profitValue = '';
      state.quantityUsdtValue = '';
    },
    addCetegory: (state, action: PayloadAction<CreatePositionCategory>) => {
      if (state.tradeList) {
        const index = state.tradeList.items.findIndex((item) => item.id === action.payload.positions_summary[0].position_summary_id);

        if (index !== -1) {
          // Replace the categories property for the found element
          state.tradeList.items[index].categories = action.payload.positions_summary[0].categories;
        }
      }
    },
    removeFigure: (state, action) => {
      state.figures = state.figures.filter((figure) => figure.figure_id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTradeList.pending, (state) => {
        if (state.tradeList) {
          state.tradeList.items = [];
        }
        state.status = EStatus.loading;
      })
      .addCase(fetchTradeList.fulfilled, (state, action) => {
        state.tradeList = action.payload;
        state.status = EStatus.success;
      })
      .addCase(fetchTradeList.rejected, (state, action) => {
        if (action.payload === 'canceled') {
          state.tradeList = null;
          return;
        }
        state.status = EStatus.rejected;
        state.tradeList = null;
      })
      .addCase(fetchCreatePositionCategory.fulfilled, (state) => {
      })
      .addCase(fetchPositionsSummaryTrades.pending, (state) => {
        state.positionSummaryTradesStatus = EStatus.loading;
        state.positionSummaryTrades = null;
      })
      .addCase(fetchPositionsSummaryTrades.fulfilled, (state, action) => {
        state.positionSummaryTradesStatus = EStatus.success;
        state.positionSummaryTrades = action.payload;
      })
      .addCase(fetchPositionsSummaryTrades.rejected, (state) => {
        state.positionSummaryTradesStatus = EStatus.rejected;
        state.positionSummaryTrades = null;
      })
      .addCase(fetchPositionSummaryPublicCreate.pending, (state) => {
        state.statusCreateSharedLink = EStatus.loading;
      })
      .addCase(fetchPositionSummaryPublicCreate.fulfilled, (state, action) => {
        state.statusCreateSharedLink = EStatus.success;
        if (state.positionSummaryTrades) {
          state.positionSummaryTrades.public_link = { ...action.payload };
        }
      })
      .addCase(fetchPositionSummaryPublicCreate.rejected, (state) => {
        state.statusCreateSharedLink = EStatus.rejected;
      })
      .addCase(fetchPositionSummaryPublicVisible.pending, (state) => {
        state.statusSharedLinkVisible = EStatus.loading;
      })
      .addCase(fetchPositionSummaryPublicVisible.fulfilled, (state, action) => {
        state.statusSharedLinkVisible = EStatus.success;
      })
      .addCase(fetchPositionSummaryPublicVisible.rejected, (state) => {
        state.statusSharedLinkVisible = EStatus.rejected;
      })
      .addCase(fetchFigures.fulfilled, (state, action) => {
        state.figures = action.payload;
      });
  },
});

export const {
  addCetegory,
  setTableColumns, setTradeFilter, resetFilters, setPage,
  setPnlValue,
  setDurationValue,
  setLeverageValue,
  setProfitValue,
  setQuantityUsdtValue,
  clearTradeListSlice,
  setResetFilters,
  removePositionSummaryTrades,
  setTickerParams,
  setCandlesStatus,
  addTradeSummary,
  updateTradeSummary,
  addPositionSummary,
  updatePositionSummuryDiary,
  updatePositionSummaryTrades,
  removeFigure,
  setSelectedPositions,
} = tradeListSlice.actions;
export const tradeListReducer = tradeListSlice.reducer;
