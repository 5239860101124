import qs from 'qs';
import { AxiosError } from 'axios';
import { useSelector, batch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/app/store/store';
import { setVerificationId } from 'src/app/store/slices/verifications/slice';

import { Tokens } from 'src/shared/types/global-types';
import { setAuth, setIsWelcomePage, setSessionId } from 'src/pages/auth/login/model/slice';
import { SMS_TYPES } from 'src/shared/constants/constants';
import { UserRegistration } from 'src/pages/auth/login/model/types';
import { selectRegistration } from 'src/pages/auth/registration/model/selectors';
import { saveTokensLocalStorage } from 'src/shared/libs/helpers/save-tokens';

import Hint from 'src/shared/ui/help/hint';
import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputText from 'src/pages/auth/components/inputs-auth/input-auth';
import InputPhone from 'src/pages/auth/components/inputs/input-phone';
import InputAction from 'src/pages/auth/components/inputs-auth/input-action';
import discordIcon from 'src/shared/assets/images/socials/discordGray.svg';
import InputActionSelect from 'src/pages/auth/components/inputs-auth/input-select';
import AuthService, { SendCodeData } from 'src/services/auth.service';

import { deleteCookie, getCookie, setCookie } from 'src/shared/libs/helpers/helper.lib';
import { ERROR_MSG_LIST } from '../../constants';
import styles from './personal-data-form.module.scss';

type PersonalData = {
  first_name: string,
  last_name: string,
  father_name?: string,
  discord_name: string,
  phone_number: string,
  phone_verification_code: number,
  send_code?: string,
  partner?: string,

}

const schema = yup.object().shape({
  first_name: yup.string().required('Обязательное поле Имя').matches(/^[a-zA-Z]+$/, 'Поле должно содержать только латинские буквы'),
  last_name: yup.string().required('Обязательное поле Фамилия').matches(/^[a-zA-Z]+$/, 'Поле должно содержать только латинские буквы'),
  father_name: yup.string().matches(/^[a-zA-Z]*$/, {
    message: 'Поле должно содержать только латинские буквы',
    excludeEmptyString: true, // Exclude check for empty string
  }),
  discord_name: yup.string().required('Обязательное поле').matches(/^[^\u0400-\u04FF]*$/, 'Поле должно содержать только латинские буквы и символы'),
  phone_number: yup.string().required('Обязательное поле'),
  phone_verification_code: yup.number().required('Обязательное поле'),
  send_code: yup.string(),
  partner: yup.string(),
});

const PersonalDataForm = () => {
  const dispatch = useAppDispatch();

  const { i18n } = useTranslation();
  const { setAlertMessage } = useAlert();

  const { email, password, regionCode } = useSelector(selectRegistration);

  const [timer, setTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCodeView, setIsCodeView] = useState(false);
  const [selectSendSmsType, setSelectSmsType] = useState('whatsapp');
  const [disabledPartnerInput, setDisabledPartnerInput] = useState(false);

  const form = useForm<PersonalData>({
    defaultValues: {
      first_name: '',
      last_name: '',
      father_name: '',
      discord_name: '',
      phone_number: '',
      phone_verification_code: undefined,
      send_code: undefined,
      partner: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    control, handleSubmit, formState, setValue,
  } = form;
  const { errors } = formState;

  const verificationPhone = async (phoneNumber: string): Promise<boolean> => {
    let result = false;

    const sendCode: SendCodeData = {
      phone_number: phoneNumber,
      email,
    };

    try {
      const response = selectSendSmsType === 'whatsapp' ? await AuthService.sendCodeWhatsApp(sendCode) : await AuthService.sendCodePhone(sendCode);
      const { data } = response;

      if (data === undefined) throw response;
      if (data) setAlertMessage('Код отправлен', 'success');

      form.clearErrors('phone_number');

      result = true;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) {
        const { detail } = error.response.data;

        if (detail === 'PHONE_EXISTS') {
          form.setError('phone_number', {
            type: 'manual',
            message: 'Этот телефон уже используется',
          });
        }

        const messageType = ERROR_MSG_LIST[detail] || 'Прозошла ошибка';
        setAlertMessage(messageType, 'error');
      } else {
        setAlertMessage('Произошла внутренняя ошибка', 'error');
      }
      result = false;
    }

    return result;
  };

  const userRegistrationRequest = async (resistrationData: PersonalData) => {
    setLoading(true);

    const newUser: UserRegistration = {
      first_name: resistrationData.first_name,
      last_name: resistrationData.last_name,
      discord_name: resistrationData.discord_name,
      father_name: resistrationData.father_name,
      language: i18n.language,
      email,
      password,
      phone_number: regionCode + resistrationData.phone_number,
    };

    if (resistrationData.partner) {
      newUser.partner = resistrationData.partner;
    }

    if (selectSendSmsType === 'phone') {
      newUser.phone_verification_code = resistrationData.phone_verification_code;
    } else {
      newUser.whatsapp_verification_code = resistrationData.phone_verification_code;
    }

    try {
      const response = await AuthService.registration(newUser);

      const { data } = response;

      if (data === undefined) throw response;

      const sessionId = data.session_id;
      const verificationId = data.verification_id;
      const tokens: Tokens = {
        access_token: data.access_token,
        refresh_token: data.refresh_token,
      };

      batch(() => {
        saveTokensLocalStorage(tokens);
        deleteCookie('partner');

        batch(() => {
          dispatch(setIsWelcomePage(true));
          dispatch(setAuth(true));

          if (sessionId) dispatch(setSessionId(sessionId));
          if (verificationId) dispatch(setVerificationId(verificationId));
        });
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) {
        const { detail } = error.response.data;
        const errorMessage = Array.isArray(detail) ? detail[0].loc[1] : detail;

        if (detail === 'INVALID_VERIFICATION_CODE') {
          form.setError('phone_verification_code', {
            type: 'manual',
            message: 'Неправильный код',
          });
        }

        const messageType = ERROR_MSG_LIST[errorMessage] || (errorMessage ? JSON.stringify(errorMessage) : 'Произошла внутренняя ошибка');
        setAlertMessage(messageType, 'error');
      } else {
        setAlertMessage('Произошла внутренняя ошибка', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (data: PersonalData) => {
    userRegistrationRequest(data);
  };

  useEffect(() => {
    if (errors.phone_verification_code && errors.phone_verification_code.message === 'Обязательное поле' && !isCodeView) {
      form.setError('send_code', {
        type: 'manual',
        message: 'Сначала нужно - Отправить код',
      });
    }
  }, [errors, isCodeView]);

  useEffect(() => {
    try {
      const partnerIdFromCookie = getCookie('partner');
      const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const queryPartnerId = queryParams.partner;

      if (queryPartnerId && typeof queryPartnerId === 'string') {
        if (queryPartnerId !== partnerIdFromCookie) {
          setCookie('partner', queryPartnerId);
        }
        setValue('partner', queryPartnerId);
        setDisabledPartnerInput(true);
      } else if (partnerIdFromCookie) {
        setValue('partner', partnerIdFromCookie);
        setDisabledPartnerInput(true);
      }
    } catch (error) {
      console.error('Error in useEffect when handling partner', error);
    }
  }, []);

  return (
    <form className={styles.personalDataForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formGroup}>
        <Controller
          name="last_name"
          control={control}
          render={({ field: { ref, ...fieldProps } }) => (
            <InputText
              ref={ref}
              error={!!errors.last_name}
              placeholder="Введите данные"
              label="Фамилия"
              {...fieldProps}
            />
          )}
        />

        <Controller
          name="first_name"
          control={control}
          render={({ field: { ref, ...fieldProps } }) => (
            <InputText
              ref={ref}
              error={!!errors.first_name}
              placeholder="Введите данные"
              label="Имя"
              {...fieldProps}
            />
          )}
        />
      </div>
      <span className={styles.inputInfo}>Фамилия, имя и отчество необходимо заполнить латинскими буквами</span>
      {errors.last_name && errors.last_name.message && <Hint error text={errors.last_name.message} />}
      {errors.first_name && errors.first_name.message && <Hint error text={errors.first_name.message} />}

      <Controller
        name="father_name"
        control={control}
        render={({ field: { ref, ...fieldProps } }) => (
          <InputText
            ref={ref}
            placeholder="Необязательное поле"
            label="Отчество"
            {...fieldProps}
          />
        )}
      />
      <span className={styles.inputInfo}>Нет отчества? Оставьте поле пустым</span>
      {errors.father_name && errors.father_name.message && <Hint error text={errors.father_name.message} />}

      <Controller
        name="discord_name"
        control={control}
        render={({ field: { ref, ...fieldProps } }) => (
          <InputText
            ref={ref}
            error={!!errors.discord_name}
            placeholder="Discord-логин"
            label="Discord"
            icon={discordIcon}
            {...fieldProps}
          />
        )}
      />
      {errors.discord_name && errors.discord_name.message && <Hint error text={errors.discord_name.message} />}

      <Controller
        name="phone_number"
        control={control}
        render={({
          field: {
            ref, value, onChange, ...fieldProps
          },
        }) => (
          <InputPhone
            ref={ref}
            isDisabled={isCodeView}
            error={!!errors.phone_number}
            placeholder="Ваш номер"
            label="Номер телефона"
            value={value || ''}
            onChange={onChange}
            {...fieldProps}
          />
        )}
      />

      {errors.phone_number && errors.phone_number.message && <Hint error text={errors.phone_number.message} />}

      { isCodeView && (
        <Controller
          name="phone_verification_code"
          control={control}
          render={({ field: { ref, ...fieldProps } }) => (
            <InputAction
              actionActive
              onClick={() => setIsCodeView(false)}
              actionTitle="Изменить номер"
              ref={ref}
              error={!!errors.phone_verification_code}
              placeholder="Введите код"
              label="Код подтверждения"
              {...fieldProps}
            />
          )}
        />
      )}
      { isCodeView && errors.phone_verification_code && errors.phone_verification_code.message && <Hint error text={errors.phone_verification_code.message} />}

      <InputActionSelect
        actionTitle="Отправить код"
        timer={timer}
        startTimer={setTimer}
        changeInput={setIsCodeView}
        verifyPhone={verificationPhone}
        sendSmsType={SMS_TYPES}
        setSelectSmsType={setSelectSmsType}
      />
      {errors.send_code && errors.send_code.message && <Hint error text={errors.send_code.message} />}

      <Controller
        name="partner"
        control={control}
        render={({ field: { ref, ...fieldProps } }) => (
          <InputText
            ref={ref}
            error={!!errors.partner}
            placeholder="ID партнера (не обязательно)"
            label="Partner ID"
            disabled={disabledPartnerInput}
            {...fieldProps}
          />
        )}
      />
      {errors.partner && errors.partner.message && <Hint error text={errors.partner.message} />}

      <Button type="submit" loading={loading} disabled={loading} background="green">Перейти в личный кабинет</Button>

      <Hint
        hintOpen
        position="bottom"
        text="Не приходит код подтверждения?"
        hintText={(
          <div>
            - Проверьте, в сети ли телефон   <br />
            - Убедитесь, что вы проверяете телефон +{regionCode}{form.getValues().phone_number} <br /> <br />
            - На получение СМС / сообщения в WhatsApp может потребоваться время. <br />
            - Попробуйте через 15 минут, если вы перепробовали все перечисленное, но письма по прежнему нет, обратитесь в техподдержку <a href="mailto:support@league.broker">support@league.broker</a>

          </div>
        )}
      />
    </form>
  );
};

export default PersonalDataForm;
