import { ReactComponent as CopyIconSvg } from 'src/shared/assets/images/copy.svg';
import { ReactComponent as CheckIconSvg } from 'src/shared/assets/images/check.svg';

import './copy-icon.scss';
import { ActionIcon, CopyButton, Tooltip } from '@mantine/core';

interface ICopyIconProps {
  onClick?: () => void
  value?: string | null | undefined
}
const CopyIcon = ({ value, onClick }: ICopyIconProps) => (
  <CopyButton value={value || ''} timeout={2000}>
    {({ copied, copy }) => (
      <Tooltip withArrow label={copied ? 'Готово' : 'Скопировать'} position="bottom">
        <ActionIcon className="copy-icon-disable" disabled={value === null || value === undefined} color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
          {copied ? (
            <CheckIconSvg />
          ) : (
            <CopyIconSvg />
          )}
        </ActionIcon>
      </Tooltip>
    )}
  </CopyButton>
);

export default CopyIcon;
