import { Suspense } from 'react';
import {
  Routes, Route, Navigate,
} from 'react-router-dom';

import { NewsPage } from 'src/pages/news';
import { AlertsPage } from 'src/pages/alerts';
import { AccountPage } from 'src/pages/account';
import { AlgoTradingPage } from 'src/pages/algo-trading';
import { ScreenerPageLazy } from 'src/pages/screener/screener-lazy';
import { DashboardPageLazy } from 'src/pages/diary/pages/dashboard/dashboard-lazy';
import { TradeListPageLazy } from 'src/pages/diary/pages/trade-list/trade-list-lazy';
import { TradeHelpPageLazy } from 'src/pages/diary/pages/help/trade-help-lazy';

// menu in aside
import { ProfilePage } from 'src/pages/profile';
import { TradingPage } from 'src/pages/trading';
import { AccountsPage } from 'src/pages/accounts';
import { SettingsPage } from 'src/pages/settings';
import { TransfersPage } from 'src/pages/transfers';
// dev
import { ComponentsPage } from 'src/pages/components';
// auth
import { LoginPage } from 'src/pages/auth/login';
import { RegistrationPage } from 'src/pages/auth/registration';
import { VerificationPage } from 'src/pages/auth/verification';
import { ForgotPasswordPage } from 'src/pages/auth/forgot-password';
import { AccessRecoveryPage } from 'src/pages/auth/access-recovery';
import { PersonalDataPage } from 'src/pages/auth/personal-data';
import { WelcomePage } from 'src/pages/welcome';
// http
import { Code404Page } from 'src/pages/http';
import LeveragesPage from 'src/pages/leverages/leverages-page';
import LoadingPageState from 'src/entities/loading-page-state/loading-page-state';

import { PositionSharedLazy } from 'src/pages/position-shared/position-shared-lazy';
import { PublicRouter } from './public-router';
import { PrivateRouter } from './private-router';

const Routers = () => (
  <Routes>
    <Route element={<PrivateRouter />}>
      <Route path="/" element={<TradingPage />} />
      <Route
        path="screener"
        element={(
          <Suspense fallback={<LoadingPageState />}>
            <ScreenerPageLazy />
          </Suspense>
        )}
      />

      <Route
        path="diary"
        element={(
          <Suspense fallback={<LoadingPageState />}>
            <DashboardPageLazy />
          </Suspense>
        )}
      />

      <Route
        path="diary/dashboard"
        element={(
          <Suspense fallback={<LoadingPageState />}>
            <DashboardPageLazy />
          </Suspense>
        )}
      />

      <Route
        path="diary/trade-list"
        element={(
          <Suspense fallback={<LoadingPageState />}>
            <TradeListPageLazy />
          </Suspense>
        )}
      />

      <Route
        path="diary/help"
        element={(
          <Suspense fallback={<LoadingPageState />}>
            <TradeHelpPageLazy />
          </Suspense>
        )}
      />

      <Route path="leverages" element={<LeveragesPage />} />
      <Route path="algo-trading" element={<AlgoTradingPage />} />
      <Route path="alerts" element={<AlertsPage />} />
      <Route path="news" element={<NewsPage />} />
      <Route path="trading" element={<TradingPage />} />
      <Route path="sub-accounts" element={<AccountsPage />} />
      <Route path="sub-accounts/:id" element={<AccountPage />} />
      <Route path="sub-accounts/:id/analytics" element={<AccountPage />} />
      <Route path="sub-accounts/:id/dust-transforms" element={<AccountPage />} />
      <Route path="transfers" element={<TransfersPage />} />
      <Route path="settings" element={<SettingsPage />} />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="profile/bonuses" element={<ProfilePage />} />
      <Route path="profile/documents" element={<ProfilePage />} />
      <Route path="profile/api-keys" element={<ProfilePage />} />
      <Route path="profile/security" element={<ProfilePage />} />
      <Route path="profile/verification" element={<ProfilePage />} />
      <Route path="404" element={<Code404Page />} />
    </Route>

    <Route element={<PublicRouter />}>
      <Route path="auth/login" element={<LoginPage />} />
      <Route path="auth/registration" element={<RegistrationPage />} />
      <Route path="auth/verification" element={<VerificationPage />} />
      <Route path="auth/personal-data" element={<PersonalDataPage />} />
      <Route path="auth/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="auth/access-recovery" element={<AccessRecoveryPage />} />
    </Route>

    <Route path="welcome" element={<WelcomePage />} />
    <Route path="components" element={<ComponentsPage />} />
    <Route path="*" element={<Navigate to="404" />} />
    <Route
      path="/trades"
      element={(
        <Suspense fallback={<LoadingPageState />}>
          <PositionSharedLazy />
        </Suspense>
      )}
    />

  </Routes>
);

export default Routers;
