import { ColumnOrderItem } from 'src/pages/trading/model/types';
import { getFromLocalStorage } from 'src/shared/libs/helpers/helper.lib';

export const transactionHistoryColumnLocalStorage = getFromLocalStorage('transactionHistoryColumnOrder', null);
export const transactionHistoryTableColumnsLocalStorage = getFromLocalStorage('transactionHistoryTableColumns', null);

export const transactionHistoryColumn: ColumnOrderItem[] = [
  { key: 'entered_at', label: 'Дата и время' },
  { key: 'instrument', label: 'Инструмент' },
  { key: 'side', label: 'Сторона' },
  { key: 'pnl', label: 'Pnl ($)' },
  { key: 'pnl_percent', label: 'Pnl (%)' },
  { key: 'quantity', label: 'Объём' },
  { key: 'quantity_dollars', label: 'Объём ($)' },
  { key: 'entry_price', label: 'Цена входа' },
  { key: 'exit_price', label: 'Цена выхода' },
  { key: 'profit', label: 'Прибыль ($)' },
  { key: 'cumulative_quantity', label: 'Оборот' },
  { key: 'commission', label: 'Комиссия ($)' },
];

export const transactionHistoryColumns = {
  entered_at: {
    show: true,
    name: 'Дата и время',
  },
  instrument: {
    show: true,
    name: 'Инструмент',
  },
  side: {
    show: true,
    name: 'Сторона',
  },
  pnl: {
    show: true,
    name: 'Pnl ($)',
  },
  pnlPercent: {
    show: true,
    name: 'Pnl (%)',
  },
  quantity: {
    show: true,
    name: 'Объём',
  },
  quantity_dollars: {
    show: true,
    name: 'Объём ($)',
  },
  entry_price: {
    show: true,
    name: 'Цена входа',
  },
  exit_price: {
    show: true,
    name: 'Цена выхода',
  },
  profit: {
    show: true,
    name: 'Прибыль ($)',
  },
  cumulative_quantity: {
    show: true,
    name: 'Оборот',
  },
  commission: {
    show: true,
    name: 'Комиссия ($)',
  },
};
